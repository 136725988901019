#branch-offices-page{
  padding-top: 10px;
  padding-bottom: 20px;

  .main-section{
    .col-branch-office{
      .box{
        position: relative;
        display: block;

        .image{
          display: block;
          height: 220px;
          border: 1px solid #e3e4e8;
          background-color: $soft-gray;
        }

        .descr{
          position: relative;
          display: block;
          margin-top: 12px;
          color: #565656;
          font-size: 0.90rem;
          font-weight: 500;
          line-height: 1.35;

          .map-icon{
            position: absolute;
            top: 0;
            right: 0;
            color: $red;
            cursor: pointer;
            font-size: 1.90rem;
            text-decoration: none !important;
            z-index: 9;
            @include transition(250ms);

            &:hover{
              opacity: 0.85;
            }
          }

          .name{
            margin-bottom: 6px;
            color: $red;
            font-size: 1.30rem;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
    }
  }

}
