#text-page{
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  color: #565656;
  font-size: 0.95rem;
  line-height: 1.30;
  text-align: justify;

  h1{
    color: $blue;
    font-weight: 600;
    text-align: center;
  }

  h5{
    font-size: 1.10rem;
    font-weight: 700;
  }

  h6{
    font-size: 0.95rem;
    font-weight: 600;
  }

  ul,ol{
    margin-bottom: 3px;
    padding-left: 17px;

    &.list-alpha{
      list-style-type: lower-alpha;
    }
  }

  strong{
    font-weight: 600;
  }
}
