.sample-products-wrapper{
  margin-left: -10px;
  margin-right: -10px;

  .sample-product{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  &.xxl-4e{
    .sample-product{
      @media screen and (min-width: 1400px){
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  &.xxl-5e{
    .sample-product{
      @media screen and (min-width: 1400px){
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

.sample-product{
  &.shad-prod{
    .outlined-box{
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }

  .outlined-box{
    cursor: pointer;
    @include transition(200ms);

    &:hover{
      text-decoration: none !important;
      opacity: 0.8;
      border-color: #797b7b;
    }
  }

  .outlined-box{
    display: inline-block;
    width: 100%;
    background-color: #ffffff;
    // border: 1px solid #E0E0E0;
    border: 0;
    border-radius: 0;

    .out-of-stock{
      position: absolute;
      padding: 4px 14px 5px 14px;
      bottom: 10px;
      right: 0;
      color: #ffffff;
      font-size: 0.80rem;
      font-weight: 500;
      text-align: center;
      background-color: #f43c3c;
    }

    .box-image{
      position: relative;
      @extend .flex-center-xy;
      height: 100%;
      text-align: center;
      border-bottom: 1px solid rgba(0,0,0,0.2);

      .img{
        position: relative;
        display: inline-block;
        max-width: 100%;
        text-align: center;

        .p-discount{
          position: absolute;
          @extend .flex-center-xy;
          top: 10px;
          right: 12px;
          padding-bottom: 3px;
          width: 54px;
          height: 54px;
          text-align: center;
          color: #ffffff;
          font-size: 1.3rem;
          font-weight: 700;
          border-radius: 50%;
          background-color: $red;
        }

        img{
          display: inline-block;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .box-shot-descr{
      display: block;
      min-height: 108px;
      // overflow: auto;
      // overflow-x: hidden;
      padding: 10px 18px 15px 18px;
      text-align: center;

      .brand{
        margin-bottom: 6px;
        color: #959595;
        font-size: 0.85rem;
        font-weight: 500;
      }

      .name{
        width: 100%;
        min-height: 39px;
        color: $blue;
        font-size: 1.1rem;
        line-height: 1.1;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
      }

      .price{
        margin-top: 3px;
        margin-bottom: 0px;
        color: $red;
        font-size: 1.1rem;
      }

      .discount{
        margin-bottom: 0;
        color: #b3b2b2;
        text-decoration: line-through;

        span{
          font-size: 92%;
        }
      }

      .descr{
        height: 35px;
        color: #939393;
        font-size: 0.85rem;
        font-weight: 300;
        line-height: 1.3;
        overflow: hidden;
      }
    }

    .p-button{
      margin-bottom: 25px;
      text-align: center;

      .btn-more{
        display: inline-block;
        padding: 5px 30px;
        width: 145px;
        max-width: 90%;
        color: #ffffff;
        font-size: 0.90rem;
        font-weight: 500;
        text-decoration: none !important;
        text-transform: uppercase;
        border-radius: 1px;
        background-color: #747474;
      }
    }
  }
}
