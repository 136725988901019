#catalog-page{

  .catalogues-section{
    padding-top: 4px;
    padding-bottom: 12px;
    
    .sample-product{
      .outlined-box{
        .box-image{
          margin-bottom: 6px;
        }

        .box-shot-descr{
          min-height: 80px !important;

          .brand{
            margin-bottom: 0;
          }
        }

        .p-button{
          .btn-more{
            color: #ffffff !important;
            border-color: $red;
            background-color: $red;

            &:hover{
              border-color: $red2;
              background-color: $red2;
            }
          }
        }
      }
    }
  }

}
