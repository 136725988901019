// == About us section ==
.about-us-section{
  padding-top: 35px;
  padding-bottom: 40px;

  .container{
    .col-info{
      color: #565656;
      font-size: 1.05rem;
      font-weight: 500;

      h2{
        margin-bottom: 15px;
        color: $blue;
        font-size: 1.90rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .col-image{
      text-align: center;
    }
  }

  @media screen and (max-width: 991px){
    padding-top: 10px;

    .container{
      .col-info{
        margin-bottom: 6px;

        h2{
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }
  }
}
// == ==

#about-us-page-v2{
  .video-section{
    padding-top: 6px;
    color: #ffffff;
    background-color: $blue;

    .col-s-title{
      margin-bottom: 10px;

      .title{
        color: #fff;
      }
    }

    .col-video{
      iframe{
        width: 100%;
        height: 580px;

        @media screen and (max-width: 991px){
          height: 400px;
        }
        @media screen and (max-width: 575px){
          height: 300px;
        }
      }
    }
  }
}
