#header{
	// == Payments ==
	.header-content{
		position: relative;
		width: 100%;

		.box-buy{
	    color: #ffffff;
			font-weight: 300;
	    background-color: $blue;

	    .container{
	      display: flex;
	      flex-wrap: wrap;
	      padding-top: 10px;
	      padding-bottom: 10px;
	      font-size: 0.90rem;

	      .col-ml, .col-ssl{
	        color: #ffffff;
	        text-align: center;
	      }
	      .col-ml{
	        h5{
	          font-size: 0.95rem;
	          font-weight: 600;
	        }
	      }
	      .col-ssl{
	        max-width: 170px;
	        font-size: 1rem;
	        font-weight: 300;

	        img{
	          margin-right: 7px;
	        }

	        span{
	          position: relative;
	          top: 2px;
	        }
	      }

	      .col-logos{
	        text-align: right;

	        span{
						position: relative;
						top: -5px;
	          margin-right: 13px;
						font-weight: 600;
	        }
	        img{
	          margin: 0 6px;
	        }

					.icon{
						position: relative;
						margin: 0 4px;
						font-size: 27px;
					}

					.img-icon{
						position: relative;
						margin-top: -12px;
						max-height: 25px;
					}

	        @media screen and (min-width: 992px) and (max-width: 1199px){
	          span{
	            font-size: 1.0rem;
	            font-weight: 600;
	          }
	          img{
	            max-height: 19px;
	          }
	        }
	      }

				@media screen and (min-width: 992px) and (max-width: 1199px){
					padding-top: 8px;
					padding-bottom: 7px;

	        .col-ml{
	          h5{
							font-size: 0.85rem;
						}
	        }
					.col-ssl{
						max-width: 140px;

						img{
							width: 30px;
						}
						span{
							font-size: 0.85rem;
						}
					}
					.col-logos{
						span{
							font-size: 0.90rem;
						}

						.icon{
							margin: 0 2px;
							font-size: 23px;
						}
						.img-icon{
							max-height: 24px;
						}
					}
	      }
	      @media screen and (max-width: 991px){
					padding-top: 8px;
					padding-bottom: 7px;

	        .col-ml, .col-ssl{
	          display: none;
	        }

					.col-logos{
						text-align: center;
					}
	      }
	      @media screen and (max-width: 767px){
	        .col-logos{
	          span{
	            // display: block;
	            text-align: center;
	            font-size: 11px;
	            font-weight: 600;
	            margin-right: 5px;
	            text-transform: uppercase;
	          }

	          img{
	            margin: 0 2px;
	            max-width: 75px;
	            max-height: 16px;
	          }
	        }
	      }
	    }
	    @media screen and (max-width: 575px){
	      display: none;
	    }
	  }

		.box-text{
			position: relative;
			display: block;
			padding: 4px 15px;
			width: 100%;
			color: $red;
			text-align: center;
			font-size: 1.05rem;
			font-weight: 700;
			background-color: #ffffff;
		}
	}
	// == ==

	// == Navbar ==
	.navbar{
		color: #ffffff;
		background-color: $red;
		// border-bottom: 1px solid rgba(0,0,0,0.12);

		.navbar-brand{
			padding-top: 0;
			padding-bottom: 0;

			.logo{
				max-height: 75px;
			}

			@media screen and (max-width: 991px){
				.logo{
					max-height: 37px;
				}
			}
		}

		.navbar-toggler{
			border: 0;
			font-size: 1.8rem;
			color: #ffffff;

			.navbar-toggler-icon{
				display: none;
			}
		}

		.navbar-nav{
			.nav-item{
				.nav-link{
					color: #ffffff;
				}
			}
		}

		.header-navigation{
			.header-menu{
				.simple-item, .nav-item{
					position: relative;
					padding-left: 0.1rem;
					padding-right: 0.1rem;

					.nav-link{
						position: relative;
						color: #ffffff !important;
						font-size: 0.95rem;
						font-weight: 400;
						cursor: pointer;

						&:before{
							content: " ";
							position: absolute;
							bottom: -20px;
							left: 0;
							width: 100%;
							border-bottom: 2px solid #ffffff;

							visibility: hidden;
							opacity: 0;
							@include transition(50ms);
						}
					}
				}

				.cart-item{
					position: relative;

					.nav-link{
						.num{
							display: inline-block;
							padding: 3px 1px 3px 1px;
							min-width: 18px;
							height: 18px;
							color: #fff;
							line-height: 1;
							font-style: normal;
							text-align: center;
							font-size: 0.7rem;
							font-weight: 400;
							border-radius: 6px;
							background-color: $red;
						}
					}
				}
			}

			.search-form{
				position: relative;
				width: 34vw;
				@include placeholder(#ffffff, 400, normal, 0.875rem);

				>.form-inline{
					position: relative;
					width: 100%;
				}

				.form-control{
					padding: 0.4rem 119px 0.4rem 0.5rem;
					width: 100%;
					height: auto !important;
					color: #ffffff;
					font-weight: 300;
					border-radius: 0;
					border-color: #f0f0f0;
					background-color: transparent;
				}

				.btn-search{
					position: absolute;
					padding-left: 34px;
					padding-right: 34px;
					top: 0;
					right: 0;
					width: auto;
					height: 100%;
					color: #ffffff;
					font-size: 0.875rem;
					border: 0 !important;
					// background-image: url('../images/shared/search-icon.svg');
					border-radius: 0;
					background-color: $blue;
					z-index: 4;
				}
			}

			.langs-item{
				margin-left: 10px;

				.nav-link{
					color: #ffffff;
					font-weight: 600;

					.btn-lang{
						color: rgba(255,255,255,0.65);
						font-weight: 600;
						text-decoration: none !important;
						cursor: pointer;

						&.active{
							color: #ffffff;
						}
					}
				}
			}

			.cart-item{
				.cart-icon{
					color: #ffffff;
					font-size: 1.4rem;
				}
			}

			.extra-menu{
				.dropdown-toggle{
					margin-left: 16px;
					padding-left: 17px;
					padding-right: 17px;
					background-color: #ffffff;

					>span{
						position: relative;
						top: 1px;
						color: $red;
						font-weight: 600;
					}

					&:after{
						display: none;
					}
				}
				.dropdown-icon{
					color: #ffffff;
					font-size: 1.4rem;
				}

				.dropdown-menu{
					background-color: $red;

					.dropdown-item{
						color: #ffffff;
						font-size: 0.9rem;

						&:focus, &:active, &:hover{
							color: #ffffff !important;
							background-color: $red2 !important;
						}
					}
				}
			}

			// == @media screen ==
			@media screen and (min-width: 992px){
				padding-left: 40px;

				.header-menu{
					align-items: center !important;

					.simple-item, .nav-item{
						.nav-link{
							padding-left: 0.6rem;
							padding-right: 0.6rem;

							&:hover{
								color: #000;

								&:before{
									visibility: visible;
									opacity: 1;
								}
							}
						}
					}
				}

				.extra-menu{
					.dropdown-menu{
						padding: 0.3rem 0;
						border-radius: 0;
						margin-top: 13px;
						// border-top: 0;

						.dropdown-item{
							padding: 0.2rem 1.5rem;
							font-size: 0.85rem;
							font-weight: 400;
						}
					}
				}
			}
			@media screen and(max-width: 991px){
				.header-menu{
					.simple-item, .nav-item{
						.nav-link{
							padding-top: 0.30rem;
							padding-bottom: 0.30rem;
						}
					}
				}

				.search-form{
					width: auto;
					margin-top: 3px;
					margin-bottom: 5px;

					.form-control{
						width: 100%;
						border-radius: 3px;
					}
				}

				.langs-item{
					margin-left: 0;
				}
			}
			@media screen and(max-width: 575px){
				.langs-item{
					.nav-link{
						text-align: center;

						.btn-lang{
							padding: 0 5px;
							margin: 0 4px;
						}
					}
				}
			}
			// == ==
		}
	}
	// == ==

	// == Bottom ==
	.header-bottom{
		@media screen and (max-width: 991px){
			display: none;
		}

		.navbar{
			@media screen and (min-width: 992px){
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}

			&.bg-info{
				background-color: $blue !important;
			}

			.navbar-brand{
				padding-top: 1px;
				padding-bottom: 1px;

				img{
					height: 50px;
				}

				@media screen and (min-width: 992px){
					display: none !important;
				}
			}

			.navbar-toggler{
				border: 0;

				.navbar-toggler-icon{
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
				}
			}

			#nav-collapse{
				.navbar-nav{
					.nav-item{
						@media screen and (min-width: 992px){
							margin-left: 0.8vw;
							margin-right: 0.8vw;

							&:first-child{
								margin-left: 0 !important;
							}
							&:last-child{
								margin-right: 0 !important;
							}

							.nav-link{
								text-align: center;
								line-height: 1.2;
								cursor: pointer;
							}
						}
						@media screen and (min-width: 1200px){
							&:first-child{
								margin-left: 0 !important;
							}
							&:last-child{
								margin-right: 0 !important;
							}

							@media screen and (max-width: 1300px){
								.nav-link{
									font-size: 0.88rem !important;
								}
							}
						}

						.nav-link{
							color: #ffffff;
							font-size: 1.05rem;
							font-weight: 400;
						}

						&.formato{
							@media screen and (min-width: 992px){
								margin-left: 0.2vw;
								margin-right: 0.2vw;
							}
						}
					}

					.b-nav-dropdown{
						.dropdown-menu{
							padding-top: 4px !important;
							padding-bottom: 6px !important;
							margin-top: 5px;
							border: 0;
							border-radius: 0;
							background-color: $red2;

							@media screen and (max-width: 991px){
								padding-top: 0 !important;
								margin-top: 0;
							}

							li{
								.dropdown-item{
									padding-right: 1.8rem;
									color: #fff;
									font-size: 0.90rem;
									font-weight: 300;

									&:hover, &:focus, &:active{
										color: #fff !important;
										background-color: $red !important;
									}
								}
							}
						}
					}
				}
			}

		}
	}
	// == ==

	// == Products menu ==
	.products-menu{
		position: fixed;
		padding-top: 15px;
		padding-bottom: 15px;
		top: 0;
		left: 0;
		width: 335px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
		background: $red2;
		opacity: 0;
		visibility: hidden;
		z-index: 480;
		@include cp-property(transform, translateX(-380px) !important);

		a{
			color: #fff;
		}

		.menu-bg{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			z-index: 1;
		}

		.tablist{
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			z-index: 2;

			.box-title{
				padding: 8px 0px;
				margin-bottom: 6px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}

				h4{
					font-size: 1.3rem;
				}

				.icon{
					position: absolute;
					top: 13px;
					right: 24px;
					font-size: 1.2rem;
				}
			}
		}

		.card{
			border: 0;
			border-radius: 0;
			background-color: transparent;

			.card-header{
				background-color: transparent;
				border: 0;
				border-radius: 0;
				padding: 0.2rem;

				.btn-toggle{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .trans-250;

					&:after{
						content: " ";
						position: absolute;
						top: 7px;
						right: 18px;
						width: 16px;
						height: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('../images/shared/chevron-down.svg');
						z-index: 1;
					}

					&:hover{
						opacity: 0.8;
					}
				}
			}

			.card-body{
				padding: 0.4rem 0;
				line-height: 1.3;
				background-color: $red;

				.ul-sub-options{
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li{
						margin-top: 5px;
						margin-bottom: 5px;
						display: inline-block;
						width: 100%;
						font-size: 14px;
						text-align: right;

						a{
							display: block;
							padding: 0px 1.45rem;

							&:hover{
								text-decoration: none;
								opacity: 0.8;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			visibility: visible;
			@include cp-property(transform, translateX(0px) !important);
		}
	}
	// == ==

}
