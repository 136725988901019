.footer-content{

  // == Newsletter ==
	.newsletter-section-v1{
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;
		width: 100%;
		color: #ffffff;
		background-color: $blue;

		.col-title{
			h2{
				font-size: 2.25rem;
				font-weight: 400;
				line-height: 1.2;
			}
		}

		.col-form{
			form{
				text-align: center;
				@extend .flex-center-xy;
				flex-wrap: wrap;

				.form-group{
					width: calc(100% - 150px);
					margin-bottom: 0;
					@include placeholder(#ffffff, 400, normal, 0.90rem);

					>div{
						width: 100%;
					}

					.form-control{
						padding: 0.375rem 0.75rem 0.375rem 1.00rem;
						height: 36px;
						text-align: left;
						font-size: 0.90rem;
						border-radius: 0;
						border-color: #ffffff;
						background-color: transparent;
					}
				}

				.btn-send{
					position: relative;
					padding-bottom: 8px;
					margin-left: -13px;
					width: 158px;
					height: 36px;
					color: $blue;
					font-size: 0.95rem;
					font-weight: 500;
					text-align: center;
					border-color: #ffffff !important;
					border-radius: 0;
					background-color: #ffffff !important;
				}
			}
		}

		@media screen and (max-width: 767px){
			padding-top: 20px;
			padding-bottom: 34px;

			.col-title{
				margin-bottom: 7px;
				text-align: center;
			}
		}
		@media screen and (max-width: 575px){
			.col-title{
				margin-bottom: 10px;

				h2{
					font-size: 1.80rem;
				}
			}
		}
	}
	// == ==

  // == Footer ==
  #footer{
    position: relative;
    width: 100%;
    padding: 15px 0 15px 0;
    color: #565656;
    background-color: #ffffff;
    border-top: 2px solid $blue;

    a{
      color: #565656;

      &:hover{
        opacity: 0.95;
      }
    }

    .col-footer{
      margin-top: 7px;
      margin-bottom: 7px;
      font-size: 0.90rem;
      font-weight: 500;

      &:last-child{
        margin-bottom: 0;
      }

      .title{
        margin-bottom: 8px;
        font-size: 1.05rem;
        font-weight: 700;
      }
    }

    .col-logo{
      -ms-flex-item-align: center !important;
      align-self: center !important;

      img{
        width: 190px;
        max-width: 90%;
      }
    }

    .col-networks{
      line-height: 1.4;

      @media screen and (min-width: 992px){
        flex: 0 0 70px;
        max-width: 70px;
      }

      .link-net{
        display: block;
        color: #969696;
        font-size: 1.6rem;
      }
    }

    @media screen and (max-width: 991px){
      .col-logo{
        text-align: center;
      }
      .col-networks{
        text-align: center;

        .link-net{
          display: inline-block;
          margin: 2px 5px;
        }
      }

      .col-footer{
        text-align: center;
      }
    }
    @media screen and (max-width: 575px){
      .col-logo{
        margin-top: 0 !important;

        img{
          width: 150px;
        }
      }
      .col-networks{
        text-align: center;

        .link-net{
          display: inline-block;
          margin: 2px 5px;
        }
      }

      .col-footer{
        margin-top: 3px;
        margin-bottom: 3px;
        font-size: 0.73rem;
        line-height: 1.4;

        .title{
          font-size: 0.9rem;
          margin-bottom: 0;
        }
      }
    }
  }
  // == ==

}
