// == Titles ==
.col-s-title{
  padding-top: 20px;
  padding-bottom: 14px;

  .title{
    color: $red;
    font-size: 1.95rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    // text-transform: capitalize;

    &.blue{
      color: $blue;
    }
  }
}
// == ==
