#howtobuy-page{

  .steps-section{
    padding-top: 15px;
    padding-bottom: 20px;

    .col-step{
      margin-top: 15px;
      margin-bottom: 15px;

      .box{
        position: relative;
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        height: 100%;
        flex-wrap: wrap;
        color: #ffffff;
        padding: 16px 15px 28px 15px;
        background-color: $red;

        .col-icon{
          max-width: 170px;
          text-align: center;

          .icon{
            font-size: 8.05rem;
          }
        }

        .col-text{
          font-size: 0.95rem;
          line-height: 1.35;
          text-align: justify;

          .num{
            margin-bottom: 14px;
            font-size: 4.2rem;
            line-height: 0.9;
          }

          .title{
            margin-bottom: 10px;
            font-weight: 600;
          }

          p{}
        }
      }
    }

    @media screen and (min-width: 992px){
      .col-step{
        &:nth-child(2), &:nth-child(3){
          .box{
            background-color: $blue !important;
          }
        }
      }
    }
    @media screen and (max-width: 1199px){
      .col-step{
        .box{
          .col-icon{
            padding: 0;
            max-width: 130px;

            .icon{
              font-size: 6.3rem;
            }
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      .col-step{
        &:nth-child(even){
          .box{
            background-color: $blue !important;
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      padding-top: 0;
      padding-bottom: 9px;

      .col-step{
        margin-top: 8px;
        margin-bottom: 8px;

        .box{
          .col-icon{
            flex: 0 0 100%;
            max-width: 100%;
            display: none;
          }

          .col-text{
            .num, .title{
              text-align: center;
            }
          }
        }
      }
    }

  }

}
